import { Controller } from '@hotwired/stimulus';

// Enable an input when a trigger is clicked, showing a confirmation
// message beforehand
export default class extends Controller {
  static targets = ['input'];

  enable(event) {
    event.preventDefault();
    const input = this.inputTarget;
    if (!input.getAttribute('disabled')) return;

    const warning = input.dataset.protectedInputWarning;

    if (!warning || confirm(warning)) {
      this.inputTarget.removeAttribute('disabled');
      this.inputTarget.focus();
      this.inputTarget.select();
      event.currentTarget.setAttribute('disabled', 'disabled');
    }
  }
}
