import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="printer"
export default class extends Controller {
  print() {
    let restorepage = document.body.innerHTML
    let printcontent = document.querySelector(".invoice-wrapper").cloneNode(true)

    let body = document.body
    body.innerHTML = printcontent.outerHTML
    window.print()
    body.innerHTML = restorepage
  }
}
