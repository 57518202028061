class AgeInMonthsInput extends HTMLElement {
  static get observedAttributes() {
    return ['value', 'name', 'required', 'data-hide-months'];
  }

  constructor() {
    super();

    this.value = this.getAttribute('value');
    this.inputId = this.getAttribute('input_id');
    this.roundYear = this.getAttribute('data-round-year') === 'true';
    this.required = this.getAttribute('required') === 'required';
    this.yearValue = '';
    this.monthValue = '';
    this.yearInputId = `${this.inputId}_years`;
    this.monthInputId = `${this.inputId}_months`;

    this.initializeInnerHTML();
    this.initializeHelpTooltip();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'value') {
      this.updateInputValues();
    } else if (name === 'data-hide-months') {
      this.updateMonthVisibility();
    }
    this.updateHiddenInputValue();
  }

  updateInputValues() {
    this.value = this.getAttribute('value');

    if (this.value) {
      const ageInMonths = parseInt(this.value);
      this.yearValue = Math.floor(ageInMonths / 12);
      this.monthValue = this.hideMonths ? 0 : ageInMonths % 12;
    }

    this.querySelector(`#${this.yearInputId}`).value = this.yearValue;
    this.querySelector(`#${this.monthInputId}`).value = this.monthValue;
  }

  updateMonthVisibility() {
    this.hideMonths = this.getAttribute('data-hide-months') === 'true';

    const yearLabel = this.querySelector(`label[for='${this.yearInputId}']`);
    const monthInput = this.querySelector(`#${this.monthInputId}`);
    const monthLabel = this.querySelector(`label[for='${this.monthInputId}']`);

    if (this.hideMonths) {
      yearLabel.textContent = 'years.';
      monthInput.classList.add('d-none');
      monthInput.value = '';
      monthLabel.classList.add('d-none');

      if (this.required) {
        monthInput.removeAttribute('required');
        monthInput.removeAttribute('aria-required');
      }
    } else {
      yearLabel.textContent = 'years and';
      monthInput.classList.remove('d-none');
      monthLabel.classList.remove('d-none');

      if (this.required) {
        monthInput.setAttribute('required', 'required');
        monthInput.setAttribute('aria-required', true);
      }
    }
  }

  updateHiddenInputValue = () => {
    const yearInput = this.querySelector(`#${this.yearInputId}`);
    const monthInput = this.querySelector(`#${this.monthInputId}`);
    const hiddenInput = this.querySelector(`#${this.inputId}`);

    this.yearValue = yearInput.value;
    this.monthValue = this.hideMonths ? 0 : monthInput.value;

    if (this.yearValue || this.monthValue) {
      this.value =
        parseInt(this.yearValue || '0') * 12 +
        (this.hideMonths && this.roundYear
          ? 11
          : parseInt(this.monthValue || '0'));
    } else {
      this.value = '';
    }

    hiddenInput.value = this.value;
  };

  initializeInnerHTML() {
    const name = this.getAttribute('name') || '';

    const inputClass = 'form-control numeric integer';
    const inputStyle = 'width: 120px;';

    this.innerHTML = `
      <input
        type="hidden"
        name="${name}"
        id="${this.inputId}"
        value="${this.value}"
      />
      <div class="mb-3">
        <div class="d-flex align-items-baseline age-in-months">
          <div class="d-flex flex-column align-items-baseline">
            <input class="${inputClass}" style="${inputStyle}" ${
      this.required ? 'required="required"' : ''
    } aria-required="${this.required}" type="number" step="1" id="${
      this.yearInputId
    }" min="0" data-parsley-min="0">
          </div>
          <label class="form-label mx-2 integer ${
            this.required ? 'required' : ''
          }" for="${this.yearInputId}">
            years and
          </label>
          <div class="d-flex flex-column align-items-baseline">
            <input class="${inputClass}" style="${inputStyle}" ${
      this.required ? 'required="required"' : ''
    } aria-required="${this.required}" type="number" step="1" id="${
      this.monthInputId
    }" min="0" max="11" data-parsley-range="[0, 11]">
    </div>
      <span>
        <label class="form-label mx-2 integer ${
          this.required ? 'required' : ''
        }" for="${this.monthInputId}">
          months.
          <i
            class="far fa-question-circle text-muted"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-original-title="Months are based on the full calendar month. If a birth occurred in Jan and the minimum is 4 months they will be eligible to register on any day within May, regardless of exact birth date.">
          </i>
        </label>
      </span>
      </div>
    </div>
    `;

    this.querySelector(`#${this.yearInputId}`).onchange =
      this.updateHiddenInputValue;
    this.querySelector(`#${this.monthInputId}`).onchange =
      this.updateHiddenInputValue;
  }

  initializeHelpTooltip = () => {
    setTimeout(
      () =>
        new bootstrap.Tooltip(this.querySelector('[data-bs-toggle="tooltip"]')),
      500,
    );
  };
}

AgeInMonthsInput.prototype.CUSTOM_ELEMENT_NAME = 'age-in-months-input';

if (!customElements.get(AgeInMonthsInput.prototype.CUSTOM_ELEMENT_NAME)) {
  customElements.define(
    AgeInMonthsInput.prototype.CUSTOM_ELEMENT_NAME,
    AgeInMonthsInput,
  );
}
