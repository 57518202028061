import consumer from './consumer';

if (window.location.href.includes('global_search') || window.location.href.includes('participants')) {
  consumer.subscriptions.create(
    { channel: 'AppointmentChannel' },
    {
      connected() {
        console.log('connected');
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        const params = new URLSearchParams(window.location.search);

        if (params.get('test_lane') && data.test_lane === Number(params.get('test_lane'))) {
          var href = window.location.href;
          if (!$('#dataOutOfSync').length) {
            $('.participants-form').prepend(
              '<a class="data-reload btn btn-danger btn-block" id="dataOutOfSync" href="' + href + '">Data is out of sync, reload page</a>',
            );
          }
        }
        // Called when there's incoming data on the websocket for this channel
      },
    },
  );
}
