import consumer from "./consumer"

const $data_import_id = $("#data-import").data('import-id')

if ($data_import_id !== undefined) {
  consumer.subscriptions.create(
    {
      channel: "DataImportChannel",
      id: $data_import_id
    }, {
      received(data) {
        if(data.csv_process_finished) {
          location.reload()
        }

        if(data.progress) {
          const progress = parseInt((data.progress / data.total_rows) * 100) + '%'
          $(".progress-bar").css("width", progress).text(progress)

          $("#save_import_later_button").prop("disabled", true);
          $("#import_button").prop("disabled", true);

          if (data.progress >= data.total_rows ) {
            $(".progress-bar")
              .removeClass("progress-bar-animated")
              .removeClass("bg-info")
              .addClass("bg-success")
          }
        }
      },
    })
}
