import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "wrapper"]
  static values = { hasRecord: Boolean }

  get nestedRecord() {
    return this.element.querySelector(".nested-record")
  }

  get nestedFields() {
    return this.nestedRecord.querySelector(".nested-fields")
  }

  toggle(event) {
    new bootstrap.Collapse(this.wrapperTarget, {
      toggle: false
    }).toggle()

    if (this.hasRecordValue) {
      this.remove(event)
    } else {
      const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
      this.nestedRecord.innerHTML = content
    }
  }

  remove(_event) {
    // New records are simply removed from the page
    if (this.nestedFields.dataset.newRecord == "true") {
      this.nestedFields.remove()
      return
    }

    // Existing nested form is flagged for deletion
    this.nestedFields.querySelector("input[name*='_destroy']").value = 1
  }
}