var Primary = {};

Primary.GoogleAddress = function(place) {
  this.place = place;
}

Primary.GoogleAddress.prototype.composeAddress = function(addressPartNames, nameType) {
  var thiz = this;
  var findAddressPart = function(partName) {
    return thiz.place.address_components.find(function(c) {
      return c.types.includes(partName);
    });
  }
  var addressParts = addressPartNames.map(function(partName) {
    var addressPart = findAddressPart(partName);
    if (!addressPart) {
      return '';
    }

    if (nameType == 'short') {
      return addressPart.short_name;
    } else {
      return addressPart.long_name;
    }
  });
  return addressParts.join(' ');
}

Primary.evaluateLocationRules = function(locationData, rules) {
  // List<{predicate, [coordinates|value]}>
  // LocationData{point, postal_code, county, city, state}
  let results = [];
  rules = rules.filter(function(r) {
    // Check if the location rule is in here.
    return ['location_coordinates', 'postal_code', 'county', 'city', 'state'].indexOf(r.access_rule_type) >= 0;
  })
  for (var i = 0; i < rules.length; i++) {
    var rule = rules[i];
    var result = true;
    if (rule.access_rule_type === 'location_coordinates') {
      // Evaluate location coordinates
      result = Primary.pointInPolygon(locationData.point, rule.coordinates);
    } else if (rule.access_rule_type === 'postal_code') {
      result = locationData.postal_code.toLowerCase() === rule.value.toLowerCase();
    } else if (rule.access_rule_type === 'county') {
      result = locationData.county.toLowerCase() === rule.value.toLowerCase();
    } else if (rule.access_rule_type === 'city') {
      result = locationData.city.toLowerCase() === rule.value.toLowerCase();
    } else if (rule.access_rule_type === 'state') {
      result = locationData.state.toLowerCase() === rule.value.toLowerCase();
    }

    if (rule.predicate === 'predicate_not') {
      results.push(!result);
    }

    results.push(result);
  }
  // It's always an OR at the global level
  if (results.length === 0) {
    return true;
  }
  return results.reduce(function(val, result) {
    return val || result;
  });
}

Primary.pointInPolygon = function(point, vs) {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  var x = point[0], y = point[1];

  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i][0], yi = vs[i][1];
    var xj = vs[j][0], yj = vs[j][1];

    var intersect = ((yi > y) != (yj > y))
      && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }

  return inside;
}

Primary.distanceBetweenTwoPoints = function(p1, p2) {
  var x1 = p1[0];
  var y1 = p1[1];
  var x2 = p2[0];
  var y2 = p2[1];

  var a = x1 - x2;
  var b = y1 - y2;

  return Math.sqrt( a*a + b*b );
}

Primary.googleAddressFiller = function(autocompleteTarget, address1Target, cityTarget, stateTarget, postalCodeTarget, address2Target, callback, countyTarget) {
  var autocomplete = new google.maps.places.Autocomplete(autocompleteTarget[0], {
    fields: ["address_components", "geometry.location", "formatted_address"]
  });

  autocomplete.addListener('place_changed', function() {
    var place = autocomplete.getPlace();
    if (!place.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      return;
    }

    if (place.address_components) {
      var googlePlace = new window.Primary.GoogleAddress(place);

      var address1 = Primary.normalizeString(googlePlace.composeAddress(['street_number', 'route'], 'long').trim());
      var city = Primary.normalizeString(googlePlace.composeAddress(['locality', 'postal_town'], 'long').trim());
      var state = Primary.normalizeString(googlePlace.composeAddress(['administrative_area_level_1'], 'short').trim());
      var postalCode = Primary.normalizeString(googlePlace.composeAddress(['postal_code'], 'long').trim());
      var county = Primary.normalizeString(googlePlace.composeAddress(['administrative_area_level_2'], 'long').trim());

      if (callback) {
        callback(address1, city, state, county, postalCode, place);
      } else {
        address1Target.val(address1);
        cityTarget.val(city);
        stateTarget.val(state);
        postalCodeTarget.val(postalCode);
        if (countyTarget) countyTarget.val(county);
      }
      address2Target.focus();
    }
  });
}

Primary.parseVaccineBarcode = function(barcode) {
  // 010003492815890581723102810U4275AA
  // 01 000349281589058 17 131028 10 U4275AA
  // 01 gtin 17 expiration 10 lotnumber
  if (barcode.substr(0, 2) !== '01') {
    return { error: "Barcode not recognized" };
  }
  if (barcode.substr(17, 2) !== "17") {
    return { error: "Barcode not recognized" };
  }
  if (barcode.substr(25, 2) !== "10") {
    return { error: "Barcode not recognized" };
  }

  var gtin = barcode.substr(2, 15)
  var expirationDate = barcode.substr(19, 6);
  var lotNumber = barcode.substr(27);
  return {
    gtin: gtin,
    expirationDate: expirationDate,
    lotNumber: lotNumber
  }
}


Primary.isValidDate = function(year, month, day) {
  month = month - 1;
  var d = new Date(year, month, day);
  if (d.getFullYear() == year && d.getMonth() == month && d.getDate() == day) {
    return true;
  }
  return false;
}

Primary.normalizeString = function(string) {
  if (string){
    var sanitized = string.normalize ? string.normalize("NFD") : string; // IE11 doesn't support normalize
    return sanitized.replace(/[\u0300-\u036f]/g, "");
  }
}

Primary.signatureBox = function(canvas, $dataInput, clearButton) {
  var signaturePad = new SignaturePad(canvas);
  var $canvas = $(canvas);
  var $clearButton = $(clearButton);
  function setSignatureValue() {
    var data = signaturePad.toDataURL();
    if (!signaturePad.isEmpty()) {
      $dataInput.val(data);
    } else {
      $dataInput.val("");
    }
  }

  $canvas.mouseout(setSignatureValue);
  $canvas.mouseup(setSignatureValue);
  $canvas[0].addEventListener('pointerup', setSignatureValue);

  $clearButton.click(function() {
    signaturePad.clear();
    setSignatureValue();
  });
}
export default Primary;
